.screen-sponsor {
    .sponsor-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 30px;
        height: 600px;
    }

    .sponsor-title {
        width: 100%;
        height: 220px;
        background-color: #b0b0b0;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;


        &.sponsor-banner {
            background-image: url('./../../assets/sponsor.jpg');
        }




        .title-line {
            position: absolute;
            bottom: 45px;
            height: 45px;
            width: 200px;
            left: 0;
            background-color: white;
        }

        h2 {
            position: absolute;
            bottom: 20px;
            left: 210px;
            font-size: 100px;
            color: white;
            margin: 0;
            font-family: 'Abril Fatface', cursive;
        }
    }

    .sponsor {

        width: 150px;
        height: 150px;
        margin: 5%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }

    }

    .sponsors-lineas {
        position: absolute;
        top: 200px;
        width: 500px;
        height: 500px;
        margin-left: 20px;
        z-index: 999;
        background-repeat: no-repeat;
        background-image: url('../../assets/lineas-dobles.png');
    }
}