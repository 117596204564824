@import '../../variables.scss';

.screen-book {
    padding-top: 100px;

    .container-fluid {
        background-color: rgba(243, 243, 243, 0.7);
        padding-top: 20px;
        padding-bottom: 200px;

        .book-box {
            height: 700px;
            background-color: gray;
            max-width: 900px;
            width: 100%;
        }

        .row-book-link {
            min-height: 200px;
            padding-bottom: 100px;

            .hexagon-mini-1 {
                background-image: url('./../../assets/hexagons/a1.png');
            }

            .hexagon-mini-2 {
                background-image: url('./../../assets/hexagons/a1.png');
            }
        }
    }

}

.footer-book{
    display: flex;
    justify-content: center;
}

.udla-container {

    display: flex;
    justify-content: space-around;
    width: 380px;
    font-weight: 600;
    margin-top: 15px;
    padding: 20px 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    .udla-text {

        margin-top: 25px;
        top: 50px;
        font-size: 16px;
    }

    .udla-logo {

        height: 62px;
        left: 0;

    }
}