.screen-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    h1 {
        text-align: center;
        margin-bottom: 30px;
        font-size: 50px;

    }

    .subtitle {
        width: 90%;
        padding: 0 15px 60px 15px;
        max-width: 800px;
        text-align: justify;
        margin-top: 23px;
        color: black;
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;

        .author {
            color: #A48D29;
            text-align: center;
            width: 100%;
            display: inline-block;
        }
    }

}

.body-home {
    display: flex;
    width: 80%;
}
.hexa-home{
    background-image: url('./../../assets/hexagono.png');
    width: 300px;
    height: 255px;
    z-index: -1;
    position: absolute;
    margin-right: -90px;
}

.menu-genre {
    background-color: #635938;
    width: 100%;
    color: #E4E4D1;
    padding: 20px;
    margin-bottom: 200px;

    .container-boxes {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .box-left {
            width: 100px;
            height: 20px;
            border-top: 1px solid #E4E4D1;
            border-right: 1px solid #E4E4D1;
            margin: 0 3px;
        }

        .box-right {
            width: 100px;
            height: 20px;
            border-top: 1px solid white;
            border-left: 1px solid white;
            margin: 0 3px;
        }
    }

}

.choose-genre {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.genre {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    
    &:hover{
        color: white;
    }

}

.genre-access {
    text-align: center;
    margin: 0;
    margin-bottom: 10px;

}

.btn-text {
    font-size: 40px;
    font-family: 'Abril Fatface', cursive;
}

.line-genre {
    background-color: #E4E4D1;
    width: 150px;
    height: 15px;
    margin: 0 40px;

    &:hover{
        background-color: white;
    }
}

.row-home {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 80%, rgba(255, 255, 255, 0) 100%);
    padding-top: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    
    .home-description {
        padding-right: 200px;
        text-align: justify;
    }

}

.picture-title {
    text-align: left;
    margin-top: 30px;

}


.title-h2 {
    text-align: end;
    font-weight: 500;
    font-family: 'Abril Fatface', cursive;
    font-size: 58px;
    color: #A48D29;
    margin-right: 20px;
    text-shadow: 1px 1px 2px rgb(193, 193, 193);
    margin-bottom: -14px;
}