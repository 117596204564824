@import './variables.scss';

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background-color: white;
  background-image: url('./assets/body-background.png');
  background-size: cover;
  color: $textBlack;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: 'Abril Fatface', cursive;
  font-size: 2.5em;
  color: $textBrown;
}
.hex-img-link{
    width: 225px;
    &.mini{
        width: 175px; 
    }
}
////////////////  Hex link 
.hexagon-link {
    position: relative;
    width: 225px; 
    height: 129.90px;
    margin: 64.95px 0;
    background-image: url(http://csshexagon.com/img/meow.jpg);
    background-size: auto 259.8076px;
    background-position: center;
    transform: rotate(90deg);
}

.hexTop-link,
.hexBottom-link {
    position: absolute;
    z-index: 1;
    width: 159.10px;
    height: 159.10px;
    overflow: hidden;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background: inherit;
    left: 32.95px;
}

/*counter transform the bg image on the caps*/
.hexTop-link:after,
.hexBottom-link:after {
    content: "";
    position: absolute;
    width: 225.0000px;
    height: 129.9038105676658px;
    -webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-64.9519px);
    -ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-64.9519px);
    transform:          rotate(45deg) scaleY(1.7321) translateY(-64.9519px);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    background: inherit;
}

.hexTop-link {
    top: -79.5495px;
}

.hexTop-link:after {
    background-position: center top;
}

.hexBottom-link {
    bottom: -79.5495px;
}

.hexBottom-link:after {
    background-position: center bottom;
}

.hexagon-link:after {
    content: "";
    position: absolute;
    top: 0.0000px;
    left: 0;
    width: 225.0000px;
    height: 129.9038px;
    z-index: 2;
    background: inherit;
}
////////////////  FIN Hex link 

////////////////  Hex mini 
.hexagon-mini {
    position: relative;
    width: 145px; 
    height: 83.72px;
    margin: 41.86px 0;
    background-image: url(http://csshexagon.com/img/meow.jpg);
    background-size: auto 167.4316px;
    background-position: center;
    transform: rotate(90deg);
}

.hexTop-mini,
.hexBottom-mini {
    position: absolute;
    z-index: 1;
    width: 102.53px;
    height: 102.53px;
    overflow: hidden;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background: inherit;
    left: 21.23px;
}

/*counter transform the bg image on the caps*/
.hexTop-mini:after,
.hexBottom-mini:after {
    content: "";
    position: absolute;
    width: 145.0000px;
    height: 83.71578903249574px;
    -webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-41.8579px);
    -ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-41.8579px);
    transform:          rotate(45deg) scaleY(1.7321) translateY(-41.8579px);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    background: inherit;
}

.hexTop-mini {
    top: -51.2652px;
}

.hexTop-mini:after {
    background-position: center top;
}

.hexBottom-mini {
    bottom: -51.2652px;
}

.hexBottom-mini:after {
    background-position: center bottom;
}

.hexagon-mini:after {
    content: "";
    position: absolute;
    top: 0.0000px;
    left: 0;
    width: 145.0000px;
    height: 83.7158px;
    z-index: 2;
    background: inherit;
}

////////////////  FIN Hex mini 


.btn{
    border-radius: 0;
    &.btn-outline-secondary{
        border-color: #B0B3B2;
    }
}


p.title{
    font-size: 1.5em;
}