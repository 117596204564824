@import './../../variables.scss';

.header {
    background-color: $gray;
    border-radius: 0px 0px 40px 0px;
    display: flex;
    height: 100px;
    width: 100%;

    .logo-box {
        align-items: center;
        background-image: url('./../../assets/marco-logo.png');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        min-width: 350px;
        height: auto;
        width: 350px;
        margin-right: auto;
        margin-left: auto;

        .navbar-brand {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 0;

            .logo {
                width: 100%;
                height: 80px;
            }
        }
    }

}

.header1 {

    width: 100%;
    margin-top: 4px;
    font-size: 14px;


    .nav-links-bar {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;

        a,
        button {
            color: $textGreen;
            background-color: transparent;
            border: none;
            font-size: 1em;
            font-weight: bold;
            padding: 10px 15px;
            text-decoration: none;

            &:hover,
            &.active {
                text-decoration: underline;
            }
        }
    }

    .list-menu {
        background-color: $darkGray;
        border: 1px solid $darkGray;
        top: 40px;
        left: -100px;
        display: none;
        width: 240px;
        border-radius: 10px;
        z-index: 999;

        ul {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            padding: 0 10px;
            margin: 0;

            li {
                list-style: none;
                text-decoration: none;
                margin: 0;
                padding: 10px 0;
                border-bottom: 1px solid white;
                width: 100%;
                text-align: center;

                &:last-child {
                    border-bottom: none;
                }

                a {
                    color: white;
                    width: 100%;
                    height: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        &:hover {
            display: block;
        }
    }

    .author-link-btn {
 
        &:hover,
        .active-hover {
            .author-list {
                display: block;
            }
        }
    }

    .book-link-btn {

        &:hover,
        .active-hover {
            .book-list {
                display: block;
            }
        }
    }
}

.header-space {
    padding-top: 96px;
}

.line-separator {
    width: 1px;
    height: 30px;
    margin-top: -5px;
    background-color: $textGreen;
}

.title-h1 {
    text-align: center;
    font-weight: 500;
    font-family: 'Abril Fatface', cursive;
    font-size: 28px;
    color: white;
    text-shadow: 1px 1px 2px rgb(193, 193, 193);
}