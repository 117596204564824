@import '../../variables.scss';
.screen-teachers{
    padding-top: 100px;
    .container-fluid{
        background-color: rgba(243, 243, 243, 0.7);
        padding-top: 20px;
        padding-bottom: 200px;

        .title{
            font-weight: bold;
        }
        .subtitle{
            max-width: 900px;
            width: 100%;
        }
        
        .teachers-box{
            background-color: #DADADA;
            background-image: url('../../assets/bg-teachers.png');
            background-repeat: repeat-x;
            
            .questions-box{
                max-width: 900px;
                width: 100%;
            }
        }

    }
    
}