@import './../../../variables.scss';

.btn-back{
    align-items: center;
    background-color: transparent;
    border: none;
    color: $textBlack;
    display: flex;
    font-size: 1rem;
    height: 27px;
    line-height: 16px;
    margin-left: 60px;
    margin-bottom: 30px;
    span{
        background: url('../../../assets/backArrows.png') no-repeat transparent;
        background-size: cover;
        width: 40px;
        margin-right: 5px;
        height: 41px;
    }
}