.screen-genre {
    margin-top: 55px;

    .genre-author-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 30px;
    }

    .genre-title {
        width: 100%;
        height: 220px;
        background-color: #b0b0b0;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        &.genre1 {
            //Poesía
            background-image: url('./../../assets/poesia.jpg');
        }

        &.genre2 {
            //Narrativa
            background-image: url('./../../assets/narrativa.jpg');
        }

        &.genre3 {
            //Ensayo
            background-image: url('./../../assets/ensayo.jpg');
        }

        .title-line {
            position: absolute;
            bottom: 45px;
            height: 45px;
            width: 200px;
            left: 0;
            background-color: white;
        }

        h2 {
            position: absolute;
            bottom: 20px;
            left: 210px;
            font-size: 100px;
            color: white;
            margin: 0;
            font-family: 'Abril Fatface', cursive;
        }
    }

    .author-genre {
        position: relative;
        width: 20%;
        height: 188px;
        margin: 4%;
        z-index: 20;
        cursor: pointer;


        img {
            top: 0;
            left: 0;
            font-family: 'Raleway';
        }

        .author-name {
            position: absolute;
            bottom: 10px;
            left: 110px;
            width: 120px;
            font-weight: 900;
            font-size: 22px;
            color: black;
            font-family: 'Raleway';
            line-height: 23px;
        }
    }

    .gen-lineas{
        position: absolute;
        top: 200px;
        width: 500px;
        height: 500px;
        margin-left: 20px;
        z-index: 10;
        background-repeat: no-repeat;
        background-image: url('../../assets/lineas-dobles.png');
    }
}