@import '../../variables.scss';
.screen-author{
    padding-top: 100px;
    .container-fluid{
        background-color: rgba(243, 243, 243, 0.1);
        padding-bottom: 100px;
        .row-bio{
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
            padding-top: 20px;

            .picture-author{
                text-align: center;
                display: flex;
                padding: 0;
                position: relative;
                padding-right: 10px;
            }
            .rotate-text {
                display: block;
                -webkit-transform: rotate(-90deg);
                position: absolute;
                bottom: 70px;
                right: -70px;
                font-size: 11px;

            
            }
            .bio-author{
                padding-right: 200px;
                text-align: justify;
                font-size: 15px;
                line-height: 16px;
            }
        }
        .row-links{
            background-color: white;
            padding-top: 150px;
            min-height: 325px;
            padding-bottom: 65px;
            position: relative;
            .hex{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin: 0 40px;
                width: 250px;
                z-index: 2;
                .hexagon-link-1{
                    background-image: url('./../../assets/hexagons/a1.png');
                }
                .hexagon-link-2{
                    background-image: url('./../../assets/hexagons/a1.png');
                }
                .hexagon-link-3{
                    background-image: url('./../../assets/hexagons/a1.png');
                }
                button{
                    margin-top: 10px;
                    min-width: 135px;
                }
            }
            .green-bar{
                background-color: $bgGreen;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 285px;
                z-index: 1;
            }

        }
        .row-book-link{
            min-height: 200px;
            padding-bottom: 100px;
            .hexagon-mini{
                background-image: url('./../../assets/hexagons/a1.png');
            }
        }
    }

    
    
}
