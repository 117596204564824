@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Raleway:wght@300;400;500&display=swap');


$secondary: #ffffff;

$gray: #D0CBC5;
$bgGreen: #898F65;
$darkGreen: #7e80359d;
$textGreen: #635938;
$textBrown: #4C412A;
$textBlack: #53575A;
$darkGray: #BFB6AE;
