@import '../../variables.scss';
.screen-interview{
    padding-top: 100px;
    .container-fluid{
        background-color: rgba(243, 243, 243, 0.7);
        padding-top: 20px;
        padding-bottom: 200px;
        

        .row-book-link{
            min-height: 200px;
            padding-bottom: 100px;
            .hexagon-mini-1{
                background-image: url('./../../assets/hexagons/a1.png');
            }
            .hexagon-mini-2{
                background-image: url('./../../assets/hexagons/a1.png');
            }
        }
    }
    
}