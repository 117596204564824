@import '../../variables.scss';

.screen-critical-study {
    padding-top: 100px;

    p.title {
        line-height: 20px;
    }

    .container-fluid {
        background-color: rgba(243, 243, 243, 0.7);
        padding-top: 20px;
        padding-bottom: 200px;

    }

    .book-box {
        height: 700px;
        background-color: gray;
        max-width: 900px;
        width: 100%;
    }

    .critical-study-container {
        margin-top: 50px;
        width: 100%;
        max-width: 630px;

        //titulo
        h2 {
            font-family: 'Abril Fatface', cursive;
            color: $textBlack;
            font-size: 2.2em;
            text-align: center;
            margin-bottom: 30px;

            small {
                font-size: 2rem;
            }
        }

        //parrafo general
        p {
            margin-top: 15px;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            padding: 0;
            font-size: 18px;
            line-height: 1.3em;
            text-align: justify;
            color: black;
            text-indent: 12px;

            //autor
            &.author-name {
                text-indent: 0;
                font-style: italic;
                color: black;
                margin: 30px 0;
            }

            &.p-all-init-space {
                text-indent: 0;
                margin-left: 80px;
                font-size: 17px;
            }

            &.subtitle {
                font-size: 20px;
                color: $textBlack;
                font-weight: 500;
                text-indent: 0;
                text-align: left;
                margin-top: 30px;
            }

            &.no-indent {
                text-indent: 0;
            }

            &.highlight {
                text-indent: 0;
                font-size: 1.65em;
                border-left: 15px solid black;
                margin: 30px 0;
                margin-left: 50px;
                padding: 0 0 0 10px;
                text-align: start;
            }


        }

        .ref-container {
            margin-top: 30px;

            .box {
                margin: 10px 0;
                width: 150px;
                height: 8px;
                background-color: black;
            }

            p.reference {
                font-size: 16px;
                margin-top: 5px;
                text-indent: 0;
            }
        }
    }

}


.book-box {
    height: 700px;
    background-color: gray;
    max-width: 900px;
    width: 100%;
}

.critical-study-width{
    width: 100%;
}